@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "montserrat";
    src: url("../public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

* {
    font-family: montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
